import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import Masonry from 'react-masonry-css'
import gsap from "gsap"
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  700: 2,
  500: 1
};

export default function Landscape({ data }) {

  useEffect(() => {
    const boxes = gsap.utils.toArray('.landscape-item');
    boxes.forEach((box, i) => {
      const anim = gsap.fromTo(box, {
          autoAlpha: 0,
          y: 50,
          stagger: 0.20,
          ease: "power1.in"
        }, {
          duration: 1,
          autoAlpha: 1,
          y: 0
      });
      ScrollTrigger.create({
        trigger: box,
        animation: anim,
        toggleActions: 'play none none none',
      });
    });
  }, []);

  return (
    <Layout>
      <Seo title="Chad Rieder | Minneapolis Landscape Photographer" description="Chad Rieder is a Minneapolis, MN based landscape photographer specializing in long exposure, nature, and cityscapes. Contact for availability, prints, and pricing." />
      <div className="grid-items">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry-grid"
          columnClassName="masonry-grid-col"
        >
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1668186155/landscape/minnesota-birch-forest-fall-colors.jpg"
              alt="Minnesota birch forest fall colors"
              placeholder="blurred"
            />
            <span id="caption">Minnesota birch</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534607253/landscape/glacier-point-yosemite-national-park.jpg"
              alt="Yosemite National Park"
              placeholder="blurred"
            />
            <span id="caption">Yosemite National Park</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1636919967/landscape/grand-canyon-national-park.jpg"
              alt="The Grand Canyon"
              placeholder="blurred"
            />
            <span id="caption">The Grand Canyon</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload//w_1400/v1702227408/landscape/cascade-mountains-washington.jpg"
              alt="Cascade Mountains"
              placeholder="blurred"
            />
            <span id="caption">Southern Cascade Mountains</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1621472430/landscape/redwoods-california.jpg"
              alt="Jedediah Smith Redwoods"
              placeholder="blurred"
            />
            <span id="caption">Jedediah Smith Redwoods</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1619988537/landscape/winchell-lake-bwca.jpg"
              alt="Winchell Lake BWCA"
              placeholder="blurred"
            />
            <span id="caption">Winchell Lake BWCA</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1702228604/landscape/pacific-ocean-northern-california.jpg"
              alt="Pacific Ocean Northern California"
              placeholder="blurred"
            />
            <span id="caption">Pacific Coast moods</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1638045042/landscape/apache-junction-star-trails.jpg"
              alt="Apache Junction star trails"
              placeholder="blurred"
            />
            <span id="caption">Apache Junction star trails</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1573422869/landscape/bison-badlands-national-park.jpg"
              alt="Badlands bison"
              placeholder="blurred"
            />
            <span id="caption">Badlands bison</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1619986604/landscape/stone-arch-bridge-minneapolis.jpg"
              alt="Stone arch bridge"
              placeholder="blurred"
            />
            <span id="caption">Stone Arch Bridge</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534615544/landscape/north-shore-lake-superior.jpg"
              alt="Tettegouche sea stack"
              placeholder="blurred"
            />
            <span id="caption">Tettegouche sea stack</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1567352355/landscape/johnson-falls-bwca-waterfalls.jpg"
              alt="Johnson Falls BWCA"
              placeholder="blurred"
            />
            <span id="caption">Johnson Falls BWCA</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534686752/landscape/maligne-lake-jasper-national-park.jpg"
              alt="Maligne Lake in Jasper National Park"
              placeholder="blurred"
            />
            <span id="caption">Maligne Lake in Jasper National Park</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1539793887/landscape/del-norte-coast-redwoods.jpg"
              alt="Redwoods national park"
              placeholder="blurred"
            />
            <span id="caption">Redwoods National Park</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534694877/landscape/lake-of-the-isles-minneapolis.jpg"
              alt="Lake of the Isles"
              placeholder="blurred"
            />
            <span id="caption">Lake of the Isles</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534615543/landscape/yoho-national-park.jpg"
              alt="Yoho National Park"
              placeholder="blurred"
            />
            <span id="caption">Yoho National Park</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534615516/landscape/full-moon-lake-superior.jpg"
              alt="Lake Superior full moon"
              placeholder="blurred"
            />
            <span id="caption">Lake Superior full moon</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/v1597607520/landscape/bwca-star-trails.jpg"
              alt="BWCA star trails"
              placeholder="blurred"
            />
            <span id="caption">BWCA star trails</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534615537/landscape/minnehaha-falls-black-white.jpg"
              alt="Minnehaha Falls"
              placeholder="blurred"
            />
            <span id="caption">Minnehaha Falls</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1567283638/landscape/badlands-national-park-south-dakota.jpg"
              alt="Badlands National Park"
              placeholder="blurred"
            />
            <span id="caption">Badlands National Park</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1619988567/landscape/gaskin-lake-bwca.jpg"
              alt="Gaskin Lake BWCA"
              placeholder="blurred"
            />
            <span id="caption">Gaskin Lake BWCA</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1621183280/landscape/gooseberry-falls-north-shore-mn.jpg"
              alt="Gooseberry Falls"
              placeholder="blurred"
            />
            <span id="caption">Gooseberry Falls</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534615540/landscape/minneapolis-skyline.jpg"
              alt="Minneapolis, MN skyline"
              placeholder="blurred"
            />
            <span id="caption">Minneapolis and the Mississippi</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534686742/landscape/lake-louise-banff-national-park.jpg"
              alt="Lake Louise"
              placeholder="blurred"
            />
            <span id="caption">Lake Louise in Banff National Park</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534688603/landscape/general-sherman-sequoia.jpg"
              alt="General Sherman sequoia"
              placeholder="blurred"
            />
            <span id="caption">Sequoia National Park</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1597607362/landscape/bwca-loon.jpg"
              alt="Minnesota loon"
              placeholder="blurred"
            />
            <span id="caption">Minnesota loon</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1572824713/landscape/northern-lights-minnesota.jpg"
              alt="northern lights"
              placeholder="blurred"
            />
            <span id="caption">Superior National Forest</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1567280032/landscape/maroon-bells-summer-sunrise.jpg"
              alt="Maroon Bells"
              placeholder="blurred"
            />
            <span id="caption">Maroon Bells</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534685549/landscape/minnehaha-falls-frozen-waterfall.jpg"
              alt="Minnehaha Falls frozen"
              placeholder="blurred"
            />
            <span id="caption">Minnehaha Falls frozen</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1559937322/landscape/gunflint-trail-startrails.jpg"
              alt="Gunflint star trails"
              placeholder="blurred"
            />
            <span id="caption">Gunflint star trails</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1619988589/landscape/lake-superior-north-shore.jpg"
              alt="North Shore of Lake Superior"
              placeholder="blurred"
            />
            <span id="caption">North Shore of Lake Superior</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1567283367/landscape/black-canyon-gunnison.jpg"
              alt="Black Canyon of the Gunnison"
              placeholder="blurred"
            />
            <span id="caption">Black Canyon of the Gunnison National Park</span>
          </div>
          <div className="masonry-item landscape-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1400/v1534686715/landscape/bow-lake-banff-national-park.jpg"
              alt="Bow Lake in Banff National Park"
              placeholder="blurred"
            />
            <span id="caption">Bow Lake in Banff National Park</span>
          </div>
        </Masonry>
          
      </div>

    </Layout>
  )
}
